'use client';

import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../ui/sheet';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import NavBarContent from './content';
import { useWindowSize } from 'usehooks-ts';

function TopBar() {
  const pathname = usePathname();

  useEffect(() => {
    if (pathname === '/') {
      window.history.replaceState(null, '', '/posts');
    }
  }, [pathname]);

  const renderText = !pathname ? '' : pathname.replace('/', '');

  const [sheetOpen, setSheetOpen] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 1024;
  useEffect(() => {
    if (width > 1024) {
      setSheetOpen(false);
    }
  }, [width]);

  function headerText() {
    const text = renderText.split('/');
    if (isMobile) {
      return text[0];
    } else {
      return text.join(' / ');
    }
  }

  useEffect(() => {
    if (pathname) {
      setSheetOpen(false);
    }
  }, [pathname]);

  return (
    <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
      <div className='flex justify-between overflow-hidden border-purple-600 lg:border-0 overscroll-none touch-none bg-primary'>
        <div className='flex items-center gap-2 p-2 py-4 text-lg uppercase twitterNoShadow'>
          <img className='w-7 h-7' src='https://immutable-cdn.s3.us-east-2.amazonaws.com/logo/token.svg' alt='IMT' />
          <Link href={'/'}>Immutable</Link>/
          <Link href={pathname} className='truncate '>
            {headerText()}
          </Link>
        </div>
        <SheetTrigger tabIndex={-1} className='pr-4 lg:hidden'>
          <HamburgerMenuIcon className='w-8 h-8' />
        </SheetTrigger>
      </div>

      <SheetContent className='h-full touch-none'>
        <SheetHeader className='h-full'>
          <NavBarContent />
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}

export default TopBar;
