'use client';
import { faCoins, faQuestionCircle, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Socials } from '~/ui/socials';
import { Card } from '~/ui/card';
import NavMenuItem from './item';
import { usePathname } from 'next/navigation';
import WalletConnect from '~/wallet/wallet-connect';

function NavBarContent() {
  const pathname = usePathname();

  return (
    <div className='flex flex-col h-full gap-4 py-2'>
      <Card className='pt-2 '>
        <div className='!flex  py-2 px-2 justify-around md:justify-start md:px-0 items-center md:flex-col  gap-2 '>
          <img
            className='w-14 h-14 md:h-24 md:w-24'
            src='https://immutable-cdn.s3.us-east-2.amazonaws.com/logo/token.svg'
            alt='Immutable'
          />
          <div className='flex flex-col text-center'>
            <span
              style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
              className='text-2xl text-center text-white uppercase md:my-4 md:text-2xl lg:text-3xl'
            >
              Immutable
            </span>
            <span className='text-sm text-gray-400'>Post Permanently</span>
          </div>
        </div>
      </Card>
      <div className='flex flex-col w-full gap-4'>
        <NavMenuItem
          color='purple'
          selected={pathname === '/posts'}
          icon={
            <img
              className='w-10 h-10'
              src='https://immutable-cdn.s3.us-east-2.amazonaws.com/logo/token.svg'
              alt='Immutable'
            />
          }
          page='posts'
        />
        <NavMenuItem
          color='yellow'
          selected={pathname === '/buy'}
          icon={<FontAwesomeIcon width={24} height={24} icon={faCoins} />}
          page='buy'
        />
        <NavMenuItem
          color='cyan'
          selected={pathname === '/info'}
          icon={<FontAwesomeIcon width={24} height={24} icon={faQuestionCircle} />}
          page='info'
        />
        <NavMenuItem
          color='emerald'
          selected={pathname.includes('/whitepaper')}
          icon={<FontAwesomeIcon width={24} height={24} icon={faFile} />}
          page='whitepaper'
        />
      </div>
      <div className='!m-auto'>
        <div className='flex items-center justify-center gap-4 py-4 '>
          <WalletConnect />
        </div>
        <Socials height='fit' />
      </div>
    </div>
  );
}

export default NavBarContent;
